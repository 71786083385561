.rhombus-tile {
  width: 100%;
  font-family: "Mulish", sans-serif;
  box-sizing: border-box;
  text-decoration: none;
  margin-bottom: 20px;
}

.rhombus-tile-image-container {
  width: 100%;
  aspect-ratio: 2;
}

.rhombus-tile-image {
  width: inherit;
  aspect-ratio: 2;
  border-radius: 20px;
  object-fit: contain;
}

.rhombus-title-title {
  font-size: 1.05rem;
  margin: 0.5vw 0;
  font-weight: 900;
  color: #031933;
  text-align: left;
}

.rhombus-title-desc {
  font-size: 0.95rem;
  color: #031933;
  text-align: left;
}
