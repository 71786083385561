.button {
  padding: 10px 25px;
  border-radius: 20px;
  font-family: "Space Grotesk";
  width: fit-content;
  max-width: 200px;
  font-weight: 500;
  cursor: pointer;
  white-space: nowrap;
}

.button.primary {
  background-color: #3adb90;
  color: #032125;
}

.button.secondary {
  background-color: #032125;
  color: #3adb90;
}

.button.rohmbus {
  background: linear-gradient(
    90deg,
    rgba(42, 125, 225, 1) 35%,
    rgba(0, 212, 255, 1) 100%
  );
  color: #fff;
  font-family: "Mulish", sans-serif;
  font-weight: 700;
}

.button.revvana {
  border-radius: 10px;
  background: #44D62C;
  color: #000;
  font-family: "Mulish", sans-serif;
  font-weight: 600;
}

.button.revvana:hover{
  background: #FEDB00;
  color: #000;
}

/* samsara theme style */

.button.samsara {
  color: #fff;
  font-family: "Mulish", sans-serif;
  font-size: 14px;
  background-color: #0683fb;
  border-radius: 5px;
  padding: 10px 30px;
  border: 1px solid transparent;
  transition: border 300ms;
}

.button.samsara:hover{
  border: 1px solid #fff;
}

@media (max-width: 1500px) {
}

@media (max-width: 1024px) {
}

@media (max-width: 765px) {
  .button > p {
    font-size: 12px;
  }
}
