.riskified-metrics-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.riskified-metrics-container {
  flex: 1;
  padding: 20px;
  margin-top: 64px; /* Adjust based on navbar height */
  width: 100%;
  box-sizing: border-box;
}

.riskified-metrics-container iframe {
  border: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background: white;
  width: 100%;
  height: calc(100vh - 9rem);
}

.loading-state,
.error-state {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 9rem);
  font-size: 1.2rem;
  color: #666;
}

.error-state {
  color: #dc3545;
}
