.rhombus-cards-container {
  width: 100%;
  box-sizing: border-box;
  max-width: 1440px;
  margin: auto auto;
  padding: 30px 0px;
}

.bullet-point-summary {
  margin: auto;
  padding: 0 1rem;
}



.bullet-point-summary ul {
  width: 100%;
  list-style-type: circle;
}

.bullet-point-summary li {
  width: 100%;
  padding: 5px 0;
}

.rohmbus-tiles-container {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(3, 31%);
  gap: 2%;
  width: 100%;
  box-sizing: border-box;
  max-width: 1440px;
  margin: 30px auto;
}

.spinner-container {
  margin: auto;
}

@media (max-width: 820px) {
  .rohmbus-tiles-container {
    grid-template-columns: repeat(1, 80%);
  }
}
