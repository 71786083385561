.revvana-tile {
  width: 100%;
  font-family: "Manrope", sans-serif;
  box-sizing: border-box;
  text-decoration: none;
  margin-bottom: 20px;
  background-color: #fff;
  border-radius: 20px;
}

.revvana-tile-image-container {
  width: 100%;
  aspect-ratio: 1.5;
  background-color: #fff;
  border-radius: 0;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.revvana-tile-image {
  border-radius: 0;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  width: inherit;
  aspect-ratio: 1.5;
  border-radius: 20px;
  object-fit: contain;
}

.revvana-title-title {
  font-size: 1.125rem;
  padding: 30px 6% 5px 6%;
  font-weight: 900;
  color: #031933;
  text-align: left;
}

.revvana-title-title:hover {
  color: #007aff;
}

.revvana-title-desc {
  font-size: 0.9375rem;
  color: #031933;
  text-align: left;
  padding: 10px 6% 30px 6%;
}
