@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

.top-container {
  background-color: #f3f8f6;
  padding: 40px 0px;
}

.result-title {
  font-family: "Space Grotesk";
  font-size: 45px;
  font-weight: 700;
  margin: 20px 0;
  color: #032125;
}

.result-desc {
  width: 75%;
  margin: 0 auto;
  font-family: "Inter", sans-serif;
  margin: 20px auto;
  color: #032125;
  line-height: 23px;
}

.buttons-container {
  margin: auto;
  width: fit-content;
}

.cards-parent-container {
  padding: 5% 16%;
}

.cards-container {
  display: grid;
  grid-template-columns: repeat(2, 45%);
  gap: 0% 10px;
  margin-bottom: 0;
  box-sizing: border-box;
  margin: auto;
  justify-content: center;
}

.spinner-container {
  width: 30px;
  height: 30px;
  margin: auto;
  box-sizing: border-box;
}

.add-email-section-container {
  background-color: #f3f8f6;
  padding: 56px;
}

@media (max-width: 1500px) {
  .cards-parent-container {
    padding: 5% 6%;
  }

  .cards-container {
    grid-template-columns: repeat(2, 48%);
    gap: 1%;
  }
}

@media (max-width: 765px) {
  .cards-parent-container {
    padding: 5% 0%;
  }

  .cards-container {
    display: block;
  }

  .result-desc {
    width: 80%;
    margin: 0 auto;
    font-family: "Inter", sans-serif;
    margin: 20px auto;
    color: #032125;
    line-height: 23px;
  }
}
