.navbar.samsara {
  height: 70px;
  padding: 0;
  margin: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
  background-color: white;
  z-index: 11;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 0px 2px 1px;
}

.samsara-logo {
  height: 30px;
}

.samsara-logo > g {
  fill: #000;
}

.navbar.samara.right-container {
  display: flex;
  justify-content: right;
  width: fit-content;
  gap: 20px;
}

.navbar.samara.right-container * {
  font-size: 12px;
}

.samsara-login-button {
  font-size: 12px;
  cursor: pointer;
}

.top-container.samsara {
  background-color: #00263e;
  position: relative;
  height: fit-content;
  /* height:250px; */
}

.top-container-bg-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.top-container-bg-image > img {
  width: inherit;
  height: inherit;
  object-fit: cover;
  object-position: top;
}

.top-master-container {
  height: 100%;
  position: relative;
}

.result-desc.samsara,
.result-desc.samsara * {
  color: white;
}

.samsara-cards-container {
  width: inherit;
  box-sizing: border-box;
  padding: 30px 0px;
  box-sizing: border-box;
}

.samsara-tiles-container {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(3, 25%);
  gap: 30px;
  width: 100%;
  box-sizing: border-box;
  margin: 30px auto;
}

.spinner-container {
  margin: auto;
}

@media (max-width: 820px) {
  .samsara-tiles-container {
    grid-template-columns: repeat(1, 80%);
  }

  .footer.samsara {
    flex-direction: column;
    gap: 40px;
  }

  .footer-links.samsara {
    display: grid !important;
    grid-template-columns: repeat(2, 90%);
    gap: 0;
    box-sizing: border-box;
  }

  .footer-links.samsara span {
    display: none;
  }
}

/* footer */

.footer.samsara {
  background-color: #00263e;
  display: flex;
  padding: 3% 8%;
  justify-content: space-around;
}

.footer-links.samsara {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  align-items: center;
  width: 55%;
}

.footer-links.samsara a {
  text-decoration: none;
  color: white;
  padding: 5px 10px;
  font-size: 0.75rem;
}
