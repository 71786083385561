.webless-search-tag {
  transition: background-color 0.5s ease;
}
.search-tag-container:hover ~ .webless-search-tag {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 999999999;
}

.webless-search-tag.hovered {
  background-color: rgba(0, 0, 0, 0.5);
}

.powered-by-text {
  all: unset;
  position: fixed;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  overflow: hidden;
  color: #000;
  text-align: center;
  text-overflow: ellipsis;
  font-family: Manrope;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 158.355%;
  letter-spacing: -0.015rem;
}

.search-tag-container {
  width: 50vw;
  background-color: #fff;
  padding: 1.1rem;
  border-radius: 15px;
  position: fixed;
  bottom: 3rem;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 10000000000;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  transition: all 0.5s;
  box-sizing: border-box;
}

.suggested-questions-parent-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  box-sizing: border-box;
}

@media only screen and (max-width: 600px) {
  .search-tag-container {
    width: 90vw;
  }
}

@media only screen and (min-width: 601px) and (max-width: 800px) {
  .search-tag-container {
    width: 75vw !important;
  }
}
@media only screen and (max-width: 800px) {
  .top-questions {
    flex-direction: row;
    flex-wrap: nowrap !important;
    overflow: scroll;
    width: inherit - 1.1rem;
  }
  .search-tag-container:hover .top-questions {
    flex-direction: column;
    overflow: hidden;
    width: inherit -20px;
  }
  .search-tag-container:hover .top-questions {
    flex-direction: column;
    overflow: hidden;
    width: inherit -20px;
  }
}

/* Experimental Css */

.top-questions {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
}

.top-questions > div {
  margin: 0.5rem;
}

.suggested-questions-parent-container-wrapper {
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 500ms;
}

.suggested-questions-parent-container-wrapper > div {
  overflow: hidden;
}

.search-tag-container:hover .suggested-questions-parent-container-wrapper {
  grid-template-rows: 1fr;
  margin-bottom: 0.5rem;
}
