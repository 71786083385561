.navbar-conatiner {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.navbar {
  max-width: 1440px;
  margin: 0 auto;
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Space Grotesk";
  font-weight: 500;
}

.nav-options {
  display: flex;
  gap: 2rem;
}

.nav-options a {
  color: #444444;
  text-decoration: none;
  cursor: pointer;
}

.nav-right-container {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.hamburger-icon {
  font-size: 24px;
  cursor: pointer;
  display: none;
}

.logout-btn {
  background: none;
  border: none;
  color: #5333b5;
  cursor: pointer;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 16px;
  border-radius: 50%;
  transition: all 0.2s ease;
}

.logout-btn:hover {
  background-color: rgba(83, 51, 181, 0.1);
}

@media (max-width: 1500px) {
  .nav-options {
    display: none;
  }
  .hamburger-icon {
    display: block;
  }
}

@media (max-width: 1024px) {
  .nav-options {
    display: none;
  }
  .hamburger-icon {
    display: block;
  }
}

@media (max-width: 765px) {

}
