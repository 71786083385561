.card{
    width: 100%;
    font-family: "Space Grotesk";
    padding: 6%;
    box-sizing: border-box;
}

.card img{
    width: 100%;
    background-color: #eee;
    border-radius: 5px;
    aspect-ratio: 3/1.69;
    object-fit: contain;
}

.card>p{
    font-size: 24px;
    font-weight: 500;
    margin: 18px 0;
    text-align: left;
}

.explandable-bar{
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    padding: 15px 0px;
}

.expandable-bar-title:hover>p{
    text-decoration: underline;
}

.expandable-bar-title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    color: #032125;
}

.explandable-bar>p:hover {
    text-decoration: underline;
}

.expandable-bar-desc{
    padding: 5% 0;
}

.expandable-bar-desc>p{
    font-family: sans-serif;
    font-weight: 300;
    text-align: left;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    padding: 5% 0;
}

.down-arrow{
    transition: all 150ms linear;
}


.down-arrow.rotate{
    transform: rotate(180deg);
}