.search-bar-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.search-icon > img {
  width: 0.85rem;
}

.search-bar-container input {
  outline: none;
  border: none;
  flex-grow: 2;
  font-size: 1rem;
  font-family: "Inter", sans-serif;
}
.search-bar-container input::placeholder {
  color: #33333366;
}

.search-button {
  border-radius: 50%;
  background-color: #5333b5;
  padding: 0.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  
}

.search-button.disabled {
  cursor: not-allowed;
  background-color: grey;
  opacity: 0.6;
}

