.search-page {
  min-height: 100vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
}

.top-container.search {
  background-color: #5433b515;
  /* background-color: #f3f8f6; */
  padding: 40px 0px;
  padding-top: 8rem;
}

.search-bar-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  margin: auto;
  box-sizing: border-box;
}

.cta-container {
  width: 80%;
  margin: 1% 10%;
  padding: 20px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: flex;
  justify-content: center;
}

.cta-container > a {
  font-weight: 700;
  font-size: 18px;
  color: #0000ee;
  text-decoration: underline;
  cursor: pointer;
}

.test-footer {
  background-color: teal;
  height: 4rem;
  width: 100%;
  margin: 2rem 0rem;
}

.result-desc.default * {
  color: black;
}

.hightlight-container,
.marketing-message-container {
  width: 80%;
  margin: 1% 10%;
  padding: 20px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.hightlight {
  font-size: 17px;
}

.marketing-message-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.marketing-message-container > a {
  font-weight: 700;
  font-size: 18px;
  color: #0000ee;
  text-decoration: underline;
  cursor: pointer;
}

.hightlight {
  font-size: 17px;
}

.search-tiles-container {
  width: 100%;
  padding: 20px 10%;
  box-sizing: border-box;
  overflow: hidden;
}

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  width: auto;
  gap: 20px;
}

.my-masonry-grid_column {
  background-clip: padding-box;
}

.summary-skeleton {
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.skeleton-line {
  height: 16px;
  background: #5433b520;
  border-radius: 4px;
  margin-bottom: 12px;
  width: 100%;
}

.skeleton-line-short {
  height: 16px;
  background: #5433b520;
  border-radius: 4px;
  width: 60%;
}

.skeleton-marketing-message {
  height: 24px;
  background: #5433b520;
  border-radius: 4px;
  width: 60%;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.pulse {
  animation: pulse 1.5s ease-in-out infinite;
}

.dynamic-suggested-questions-container {
  color: #000;
  width: 100%;
  padding: 10px 10%;
  box-sizing: border-box;
  margin-bottom: 40px;
}

.dynamic-suggested-questions-title {
  font-family: Manrope;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.54px;
  border-bottom: 1px solid #eee;
  padding: 10px 0;
}

.dynamic-suggested-questions {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 10px;
}

.dynamic-suggested-question-container {
  display: flex;
  align-items: center;
  padding: 10px;
  gap: 10px;
  cursor: pointer;
  border-bottom: 1px solid #eee;
  cursor: pointer;
}

.go-up-arrow {
  color: #5333b5;
}

.dynamic-suggested-question-text {
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
