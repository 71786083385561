.demo-page {
  min-height: 100vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  box-sizing: border-box;
}

.demo-parent-container {
  padding: 0rem 8rem;
  padding-top: 6rem;
}

.top-section {
  width: 100%;
  margin-bottom: 2rem;
}

.single-result-container {
  margin-bottom: 4rem;
}

.conversation-container {
  margin-bottom: 15rem;
}

.result-desc-demo {
  width: 100%;
  background-color: red;
  color: black;
  padding: 0;
  width: 100%;
  background-color: #5433b515;
  padding: 2rem;
  border-radius: 1rem;
  box-sizing: border-box;
  margin-top: 1rem;
}

.summary-btn {
  margin-top: 1rem;
}

.spinner-wrapper {
  width: 45px;
  height: 45px;
  margin: 0 auto;
  margin-top: -8%;
  margin-bottom: 15%;
  box-sizing: border-box;
}

.spinner-container.first-load {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  height: 90vh;
  width: 100%;
  box-sizing: border-box;
}

.spinner-container.first-load .spinner-wrapper {
  margin: 0;
}

.quotes-container {
  margin: auto;
}

.quotes-container-heading {
  margin: auto;
  width: 70%;
  color: #303030;
  text-align: center;
  font-family: Castoro;
  font-size: 3rem;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: -0.09rem;
}

.quotes-container-subtext {
  margin-top: 20px;
  color: #000;
  text-align: center;
  font-family: Manrope;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.03375rem;
}

.down-arrow {
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: center;
}

.down-arrow > img {
  margin-top: 20px;
  height: 100%;
  flex-shrink: 0;
  stroke-width: 5px;
  stroke: #000;
  animation: bounce2 2s ease infinite;
}

@keyframes bounce2 {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(30px);
  }
  60% {
    transform: translateY(15px);
  }
}

@media only screen and (min-width: 701px) and (max-width: 1080px) {
  .demo-parent-container {
    padding: 0rem 5rem;
    padding-top: 6rem;
  }
}

@media only screen and (max-width: 700px) and (max-width: 1080px) {
  .demo-parent-container {
    padding: 0 2rem;
    padding-top: 6rem;
  }
}
