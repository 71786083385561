
.suggested-query {
  font-family: Manrope;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
}

.suggested-queries-container > .suggested-query:last-child {
  border-bottom: none;
}
