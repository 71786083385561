.footer {
  background-color: #032125;
  padding: 5% 10% 2% 10%;
  width: 100%;
  text-align: left;
  font-family: "Space Grotesk";
  box-sizing: border-box;
  color: #d1d5db;
}

.footer-top-container {
  display: flex;
  gap: 5%;
}

.contact-us {
  /* width: 30%; */
  color: white;
  font-size: 14px;
  line-height: 24px;
}

.footer-footer-logo-desc {
  font-size: 14px;
  color: #d1d5db;
  font-weight: 200;
  font-family: "Inter", sans-serif;
  padding: 10px 0;
}

.icons-row {
  width: 30%;
  display: flex;
  margin-top: 10px;
  color: #6b7280;
  font-size: 22px;
  gap: 10px;
}

.icons-row > :hover {
  color: #8b93a5;
}

.right-container {
  display: grid;
  grid-template-columns: repeat(4, 25%);
  /* gap: 8%; */
  flex-grow: 2;
}

.links-section-container {
  display: flex;
  gap: 40px;
}

.links-section {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.footer-links-title {
  font-size: 14px;
  color: #d1d5db;
  font-weight: 600;
  font-family: "Inter", sans-serif;
  margin-bottom: 10px;
}

.footer-links {
  font-size: 14px;
  color: #d1d5db;
  font-weight: 200;
  font-family: "Inter", sans-serif;
  padding: 10px 0;
}

.horizontal-row {
  display: flex;
}

.footer-bottom-container {
  border-top: 0.5px solid #d1d5db31;
  margin-top: 5%;
}

.offices-text {
  font-size: 13px;
  font-weight: 400;
  margin: 10px 0;
  margin-top: 30px;
}

.copy-right-row {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 12px;
  font-weight: 100;
  letter-spacing: 1px;
  padding: 5px 0;
}

.policy-terms-container {
  display: flex;
  align-items: center;
  color: #3adb90;
  font-size: 12px;
  font-weight: 100;
  gap: 5px;
}

.primary-color {
  color: #3adb90;
}

@media (max-width: 1500px) {
  .footer {
    padding: 5% 8% 2% 8%;
    margin: auto;
  }
}

@media (max-width: 1370px) {
  .footer-top-container {
    flex-direction: column;
    gap: 50px;
  }

  .links-section-container {
    width: inherit;
    gap: 10%;
  }
}

@media (max-width: 765px) {
  .searchbar {
    width: 80%;
  }

  .right-container {
    grid-template-columns: repeat(2, 50%);
  }
}
