.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.9rem 2.5rem;
  border-bottom: 1px solid #f5f5f5;
  position: fixed;
  height: fit-content;
  width: 100%;
  box-sizing: border-box;
  z-index: 100;
  background-color: #fff;
}

.navbar-left-container {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.nav-logo-container img {
  height: 30px;
}

.navbar-right-container-hamburger {
  display: none;
  color: #5333b5;
}

.navbar-right-container-buttons-wrapper {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.hamburger-options {
  position: absolute;
  top: 3.5rem;
  right: 0;
  width: 100vw;
  padding: 1rem 0;
  /* height: calc(100vh - 50px); */
  height: fit-content;
  /* background-color: rgba(0, 0, 0, 0.9); */
  background-color:#fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  z-index: 10;
  font-family: Manrope;
  box-sizing: border-box;
}

.hamburger-link {
  color: #000;
  font-size: 1.5rem;
  text-decoration: none;
}

.logout-btn {
  background: none;
  border: none;
  color: #5333b5;
  cursor: pointer;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  transition: all 0.2s ease;
}

.desktop-logout {
  margin-left: 8px;
}

.logout-btn:hover {
  background-color: rgba(83, 51, 181, 0.1);
}

.hamburger-link.logout-btn {
  width: 100%;
  justify-content: flex-start;
  font-size: 14px;
}

@media only screen and (max-width: 900px) {
  .navbar-container {
    padding: 0.9rem 1rem;
  }

  .navbar-right-container-buttons-wrapper {
    display: none;
  }

  .navbar-right-container-hamburger {
    display: block;
    cursor: pointer;
  }
}
