.samsara-tile {
  width: 100%;
  font-family: "Roboto", sans-serif;
  box-sizing: border-box;
  text-decoration: none;
  margin-bottom: 20px;
  box-shadow: rgba(50, 50, 93, 0.106) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.222) 0px 3px 7px -3px;
  border-bottom: 3px solid white;
  transition: border 300ms;
}

.samsara-tile:hover {
  border-bottom: 3px solid #0683fb;
}

.samsara-tile-image-container {
  width: 100%;
  aspect-ratio: 1.3;
}

.samsara-tile-image {
  width: inherit;
  aspect-ratio: 1.3;
  border-radius: 20px;
  object-fit: contain;
}

.samsara-tile-desc-section {
  padding: 20px 20px;
}

.samsara-title-title {
  font-size: 1.25rem;
  margin-top: 20px;
  font-weight: 500;
  letter-spacing: 0.5px;
  word-spacing: 3px;
  color: #031933;
  text-align: left;
  font-family: "Roboto", sans-serif;
}

.samsara-title-desc {
  margin-top: 10px;
  font-size: 0.85rem;
  line-height: 1.1rem;
  font-weight: 300;
  color: #031933;
  text-align: left;
}
