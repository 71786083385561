.suggested-question {
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: #ecfae9;
  padding: 7px 14px;
  border-radius: 5px;
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  color: #000;
  font-weight: 400;
  box-shadow: #fff 0px 2px 5px -1px, rgba(255, 255, 255, 0.3) 0px 1px 3px -1px;
  box-sizing: border-box;
  border: 1px solid transparent;
  transition: border 250ms;
}

.suggested-question:hover {
  cursor: pointer;
  border: 1px solid #44d62c;
}

.suggested-question.demo:hover {
  cursor: pointer;
  border: 1px solid #5433b5ca;
}

.suggested-question-star-icon {
  color: #44d62c;
}

/* custom style for company */

/* samsara */

.suggested-question-star-icon.samsara {
  color: #fff;
}

.suggested-question.samsara {
  background-color: #00263e;
  color: #fff;
  box-shadow: none;
  border: 1px solid #fff;
}

.suggested-question.samsara:hover {
  background-color: #0683fb;
}

.suggested-question.demo{
  background-color: #5433b5;
  color: #fff;
  border-radius: 0.7rem;
  margin: 0;
}

.suggested-question-star-icon.demo {
  color: #fff;
}