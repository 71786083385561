.top-container.revvana {
  background-color: #fff;
}

.suggested-question-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  margin: 0 auto;
  width: 45%;
}

.result-desc.revvana {
  font-family: "Manrope", sans-serif !important;
  font-size: 15px;
}


.revvana-cards-container {
  background-color: #efefef;
  padding: 20px;
  min-height: 80vh;
}

.revvana-tiles-container {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(3, 31%);
  gap: 2%;
  box-sizing: border-box;
  max-width: 1440px;
  margin: 0px auto;
}

@media (max-width: 820px) {
  .revvana-tiles-container {
    grid-template-columns: repeat(1, 90%);
    gap: 1%;
  }
}

@media (max-width: 1500px) {
  .suggested-question-container {
    width: 70%;
  }
}

@media (max-width: 765px) {
  .suggested-question-container {
    width: 80%;
    justify-content: start;
  }
}