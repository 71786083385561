.searchbar-parent-container {
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
  border-radius: 10px;
}

.searchbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px solid #003029;
  /* border-radius: 10px 10px 0px 0px; */
  border-radius: 10px;
  padding: 5px 10px;
  gap: 10px;
  /* margin: 30px auto; */
  /* width: 45%; */
  -webkit-box-shadow: -1px 2px 30px 0px rgba(57, 219, 143, 1);
  -moz-box-shadow: -1px 2px 30px 0px rgba(57, 219, 143, 1);
  box-shadow: -1px 2px 30px 0px rgba(57, 219, 143, 1);
}

.searchbar.default {
  width: 100%;
  box-sizing: border-box;
  border: 1px solid white;
  /* border-radius: 0 0 10px 10px !important; */

  border: none;
  -webkit-box-shadow: -1px 2px 30px 0px #aaa;
  -moz-box-shadow: -1px 2px 30px 0px #aaa;
  box-shadow: -1px 2px 30px 0px #aaa;
  /* background-color: white; */
}

.searchbar-input-field {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
  font-size: 22px;
  width: 90%;
}

.search-icon {
  font-size: 22px;
  color: #39db8f;
}

.searchbar input {
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 18px;
  width: 100%;
}

.search-button {
  background-color: #39db8f;
  border: none;
  height: inherit;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  font-size: 22px;
  color: white;
  cursor: pointer;
}

.search-button.disabled {
  background-color: #ccc !important;
  cursor: not-allowed;
}

@media (max-width: 1500px) {
  .searchbar {
    width: 70%;
  }
}

@media (max-width: 765px) {
  .searchbar {
    width: 80%;
  }
}

/* searchbar theme css */

.search-button.rhombus {
  background-color: #00d4ff;
}

.search-icon.rhombus {
  color: #00d4ff;
}

.searchbar.rhombus {
  -webkit-box-shadow: -1px 2px 30px 0px #00d4ff;
  -moz-box-shadow: -1px 2px 30px 0px #00d4ff;
  box-shadow: -1px 2px 30px 0px #00d4ff;
}

/* revvana theme css */

.searchbar.revvana {
  background-color: #fff;
  -webkit-box-shadow: -1px 2px 12px 0px #44d62c;
  -moz-box-shadow: -1px 2px 12px 0px #44d62c;
  box-shadow: -1px 2px 12px 0px #44d62c;
  border: 1px solid #44d62c;
}

.search-input.revvana {
  font-weight: 400;
}

.search-input.revvana::placeholder {
  color: #808285;
}

.search-button.revvana {
  background-color: #44d62c;
}

.search-icon.revvana {
  color: #44d62c;
}

/* samsara theme style */

.search-button.samsara {
  background-color: #00263e;
}

.search-icon.samsara {
  color: #00263e;
}

.searchbar.samsara {
  border: 1px solid white;
  -webkit-box-shadow: -1px 2px 30px 0px #0683fb;
  -moz-box-shadow: -1px 2px 30px 0px #0683fb;
  box-shadow: -1px 2px 30px 0px #0683fb;
  background-color: white;
}

/* samsara theme style */

.search-button.default {
  background-color: #5333b5;
  border-radius: 10px;
}

.search-icon.default {
  color: #000;
}

.suggested-queries-container {
  /* background-color: #fff; */
  /* border-radius: 10px; */
  width: 100%;
  margin-top: 0;
}
