.skeleton-container {
  width: 100%;
  margin-bottom: 20px;
}

.skeleton-tile {
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 20px;
  box-sizing: border-box;
}

.skeleton-image {
  width: 100%;
  height: 200px;
  background: #5433b520;
  border-radius: 10px;
  margin-bottom: 10px;
}

.skeleton-content {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.skeleton-title {
  height: 24px;
  background: #5433b520;
  border-radius: 4px;
  width: 80%;
  margin: 10px 0px;
}

.skeleton-text {
  height: 14px;
  background: #5433b520;
  border-radius: 4px;
  width: 100%;
}

.skeleton-text-short {
  height: 14px;
  background: #5433b520;
  border-radius: 4px;
  width: 60%;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.pulse {
  animation: pulse 1.5s ease-in-out infinite;
}
